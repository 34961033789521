import React, { Component } from 'react';
import Slides from '../Assets/Images/slide-3.jpg';
import ProgressBar from './ProgressBar';

 // import CV from '../Assets/Heartman_Sibanda_CV.pdf'
class About extends Component {
    render() {
        return (
            <div className="hx-about-style-1" id="about">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-6 col-lg-6 d-xl-flex d-lg-flex d-block align-items-center">
                            <div className="hx-about-content">
                                <div className="hx-site-title">
                                    <span>Fullstask Developer</span>
                                    <h2>About Me</h2>
                                </div>
                                <p>
                                    I am a Full Stack Developer, who enjoys helping businesses and individuals realize their dreams using :
                                </p>

                                <div>

                                    <ProgressBar language="Python" progress="90%" />
                                    <br />
                                    <ProgressBar language="Django" progress="85%" />
                                    <br />
                                    <ProgressBar language="React" progress="70%" />
                                    <br />
                                    <ProgressBar language="Docker" progress="75%" />
                                    <br />
                                    <ProgressBar language="Bootstrap" progress="60%" />
                                    <br />
                                    <ProgressBar language="RabbitMQ" progress="75%" />
                                    <br />
                                    <ProgressBar language="PostgreSQL" progress="83%" />
                                    <br />
                                    <ProgressBar language="Redis" progress="72%" />
                                    <br />
                                    <ProgressBar language="Celery" progress="60%" />
                                    <br />
                                    <ProgressBar language="Nginx" progress="70%" />
                                    <br />
                                    <ProgressBar language="Gunicon" progress="60%" />
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 mt-5 pt-5 ">
                            <div className="hx-about-img mt-5 pt-5">
                                <img alt="" src={Slides} />
                            </div>
                            {/* <div className="btns">
                                <a href={CV} className="theme-btn">Download CV</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;