import React, { Component } from 'react';
import serviceImg_1 from '../Assets/Images/1.png';
import serviceImg_2 from '../Assets/Images/2.png';
import serviceImg_3 from '../Assets/Images/3.png';
import serviceImg_4 from '../Assets/Images/4.png';
import serviceImg_5 from '../Assets/Images/5.png';
import serviceImg_6 from '../Assets/Images/6.png';

class Service extends Component {
    render() {
        return (
            <div id="service" className="hx-service-area ptb-100-70">
                <div className="container">
                    <div className="col-12">
                        <div className="section-title text-center">
                            <span>What I Use</span>
                            <h2>Tools</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 grid col-12">
                            <div className="hx-service-item">
                                <div className="hx-service-single">
                                    <div className="service-img">
                                        <img alt="" src={serviceImg_2} className="ofcourse" />
                                    </div>
                                    <div className="hx-service-content">
                                        <h3>Python</h3>
                                        <p>I am Expert In</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 grid col-12">
                            <div className="hx-service-item">
                                <div className="hx-service-single">
                                    <div className="service-img">
                                        <img alt="" src={serviceImg_1} className="ofcourse" />
                                    </div>
                                    <div className="hx-service-content">
                                        <h3>JavaScript</h3>
                                        <p>I am Expert In</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 grid col-12">
                            <div className="hx-service-item">
                                <div className="hx-service-single">
                                    <div className="service-img">
                                        <img alt="" src={serviceImg_3} className="ofcourse" />
                                    </div>
                                    <div className="hx-service-content">
                                        <h3>Clojure</h3>
                                        <p>I am Expert In</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 grid col-12">
                            <div className="hx-service-item">
                                <div className="hx-service-single">
                                    <div className="service-img">
                                        <img alt="" src={serviceImg_4} className="ofcourse" />
                                    </div>
                                    <div className="hx-service-content">
                                        <h3>Django</h3>
                                        <p>I am Expert In</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 grid col-12">
                            <div className="hx-service-item">
                                <div className="hx-service-single">
                                    <div className="service-img">
                                        <img alt="" src={serviceImg_5} className="ofcourse" />
                                    </div>
                                    <div className="hx-service-content">
                                        <h3>React</h3>
                                        <p>I am Expert In</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 grid col-12">
                            <div className="hx-service-item">
                                <div className="hx-service-single">
                                    <div className="service-img">
                                        <img alt="" src={serviceImg_6} className="ofcourse" />
                                    </div>
                                    <div className="hx-service-content">
                                        <h3>Three JS</h3>
                                        <p>I am Expert In</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Service;