import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import g3zb2a from '../Assets/Images/p1-g3zb2a.png';
import ttfn70 from '../Assets/Images/p2-b3gnym.png';
import b3gnym from '../Assets/Images/p2-b3gnym.png';
import avirap from '../Assets/Images/p4-avirap.png';

import { faFacebookF, faTwitter, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



class Banner extends Component {
    componentDidMount() {
        let renderer,
            scene,
            camera,
            stars,
            controls,
            container = document.getElementById("canvas_container"),
            timeout_Debounce;
        scene = new THREE.Scene();

        camera = new THREE.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 0.01, 1000)
        camera.position.set(0, 0, 100);

        const directionalLight = new THREE.DirectionalLight("#fff", 2);
        directionalLight.position.set(0, 50, -20);
        scene.add(directionalLight);

        let ambientLight = new THREE.AmbientLight("#ffffff", 1);
        ambientLight.position.set(0, 20, 20);
        scene.add(ambientLight);

        renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true
        });
        renderer.setSize(container.clientWidth, container.clientHeight);
        renderer.setPixelRatio(window.devicePixelRatio);
        container.prepend(renderer.domElement);

        //OrbitControl
        controls = new OrbitControls(camera, renderer.domElement);
        controls.autoRotate = true;
        controls.autoRotateSpeed = 4;
        controls.maxDistance = 350;
        controls.minDistance = 150;
        controls.enablePan = false;
        controls.enableZoom = false;

        const loader = new THREE.TextureLoader();
        const textureStar = loader.load(g3zb2a);
        const texture1 = loader.load(b3gnym);
        const texture2 = loader.load(ttfn70);
        const texture4 = loader.load(avirap);

        /*    Moving Stars   */
        let starsGeometry = new THREE.Geometry();

        for (let i = 0; i < 50; i++) {
            let particleStar = randomPointSphere(150);

            particleStar.velocity = THREE.MathUtils.randInt(50, 200);

            particleStar.startX = particleStar.x;
            particleStar.startY = particleStar.y;
            particleStar.startZ = particleStar.z;

            starsGeometry.vertices.push(particleStar);
        }
        let starsMaterial = new THREE.PointsMaterial({
            size: 5,
            color: "#ffffff",
            transparent: true,
            opacity: 0.8,
            map: textureStar,
            blending: THREE.AdditiveBlending,
        });
        starsMaterial.depthWrite = false;
        stars = new THREE.Points(starsGeometry, starsMaterial);
        scene.add(stars);


        /*    Fixed Stars   */
        function createStars(texture, size, total) {
            let pointGeometry = new THREE.Geometry();
            let pointMaterial = new THREE.PointsMaterial({
                size: size,
                map: texture,
                blending: THREE.AdditiveBlending,
            });

            for (let i = 0; i < total; i++) {
                let radius = THREE.MathUtils.randInt(149, 70);
                let particles = randomPointSphere(radius);
                pointGeometry.vertices.push(particles);
            }
            return new THREE.Points(pointGeometry, pointMaterial);
        }
        scene.add(createStars(texture1, 15, 20));
        scene.add(createStars(texture2, 5, 5));
        scene.add(createStars(texture4, 7, 5));


        function randomPointSphere(radius) {
            let theta = 2 * Math.PI * Math.random();
            let phi = Math.acos(2 * Math.random() - 1);
            let dx = 0 + (radius * Math.sin(phi) * Math.cos(theta));
            let dy = 0 + (radius * Math.sin(phi) * Math.sin(theta));
            let dz = 0 + (radius * Math.cos(phi));
            return new THREE.Vector3(dx, dy, dz);
        }
        var onWindowResize = function () {
            camera.aspect = container.clientWidth / container.clientHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(container.clientWidth, container.clientHeight);
        }
        var animate = function () {
            //Stars  Animation
            stars.geometry.vertices.forEach(function (v) {
                v.x += (0 - v.x) / v.velocity;
                v.y += (0 - v.y) / v.velocity;
                v.z += (0 - v.z) / v.velocity;

                v.velocity -= 0.3;

                if (v.x <= 5 && v.x >= -5 && v.z <= 5 && v.z >= -5) {
                    v.x = v.startX;
                    v.y = v.startY;
                    v.z = v.startZ;
                    v.velocity = THREE.MathUtils.randInt(50, 300);
                }
            });

            controls.update();
            stars.geometry.verticesNeedUpdate = true;
            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };
        window.addEventListener("resize", () => {
            clearTimeout(timeout_Debounce);
            timeout_Debounce = setTimeout(onWindowResize, 80);
        });
        animate();
        // === THREE.JS EXAMPLE CODE END ===
    }
    render() {
        return (
            <section className="hero hero-slider-wrapper hero-style-1" id="home2">
                <div className="" id="canvas_container" >
                    <div className="slider" >
                        <div className="container" id="canvas_con">
                            <div className="row">
                                <div className="col col-md-8 col-sm-12 slide-caption">
                                    <div className="slide-subtitle">
                                        <h4>I Am Heartman Sibanda</h4>
                                    </div>
                                    <div className="slide-title">
                                        <h2>Full-Stack Developer</h2>
                                    </div>
                                    <div className="btns">
                                        <a href="#contact" className="theme-btn go-contact-area">Contact Me</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy"><p>Full-Stack Developer</p></div>
                <div className="social-links">
                    <ul>

                        <li>
                            <a href="https://github.com/HeartmanTalent" target='_bank'>
                                <FontAwesomeIcon icon={faGithub} />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/HeartmanTalent" target='_bank'>
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/heartmansibanda/" target='_bank'>
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/heartman.sibanda" target='_bank'>
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default Banner;