import React, { Component } from 'react';

class ProgressBar extends Component {
    render() {
        return (
            <div className="container">
                <div className="progressbar-container">
                    <div className="progressbar-complete" style={{ width: this.props.progress }}>
                        <div className="progressbar-liquid"></div>
                    </div>
                    <span className="progress2">{this.props.language}</span>
                </div>
            </div>
        )
    }
}

export default ProgressBar;