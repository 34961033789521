import React, { Component } from 'react';
import axios from "axios"

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            success: false,
            error: false,
        }
    }
    updateName = (event) => {
        this.setState({
            name: event.target.value
        })
    }
    updateEmail = (event) => {
        this.setState({
            email: event.target.value
        })
    }
    updateMessage = (event) => {
        this.setState({
            message: event.target.value
        })
    }
    postFeedback = () => {
        axios.post("https://server.heartmantalent.com/feedback", { name: this.state.name, email: this.state.email, message: this.state.message }, { "Access-Control-Allow-Origin": "*" })
            .then((response) => {
                this.setState({
                    success: true,
                    error: false,
                    name: '',
                    email: '',
                    message: '',
                })
            }, [])
            .catch((err) => {
                console.log(err)
                this.setState({
                    error: true,
                    success: false,
                })
            })
    }
    render() {
        return (
            <div id="contact" className="contact-page-area section-padding go-contact-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-5">
                            <div className="contact-page-item">
                                <div className="adress">
                                    <h3>Address</h3>
                                    <span>245 King George, Avondale, Harare, Zimbabwe
                                    </span>
                                </div>
                                <div className="phone">
                                    <h3>Phone</h3>
                                    <span> <a href="tel:|263775527752">263-775-527-752</a> </span>
                                    <span> <a href="tel:|263784310033">263-784-310-033</a> </span>
                                </div>
                                <div className="email">
                                    <h3>Email</h3>
                                    <span>sibandaheartman[at]gmail.com</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-7">
                            <div className="contact-area contact-area-2 contact-area-3">
                                <h2>Quick Contact Form</h2>
                                <div className="contact-form">
                                    <div className="half-col">
                                        <input type="text" name="name" id="name" value={this.state.name} className="form-control" placeholder="Your Name" onChange={this.updateName} />
                                    </div>
                                    <div className="half-col">
                                        <input type="email" name="email" id="email" value={this.state.email} className="form-control" placeholder="Your Email" onChange={this.updateEmail} />
                                    </div>
                                    <div>
                                        <textarea className="form-control" name="note" id="note" value={this.state.message} placeholder="Your Message..." onChange={this.updateMessage}></textarea>
                                    </div>
                                    <div className="submit-btn-wrapper">
                                        <button className="theme-btn-s3" onClick={this.postFeedback}>Submit</button>
                                        <div id="loader">
                                            <i className="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                                        </div>
                                    </div>
                                    <div className="clearfix error-handling-messages">
                                        {this.state.success &&
                                            <div id="success">Thank you</div>
                                        }
                                        {this.state.error &&
                                            <div id="error"> Error occurred while sending email. Please try again later. </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="contact-map">
                                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15195.807665095219!2d31.02713653689313!3d-17.793956805377544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931a5779bbc282b%3A0x1adf2004b440bf6c!2sAvondale%2C%20Harare!5e0!3m2!1sen!2szw!4v1647157882177!5m2!1sen!2szw" allowFullScreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;